@font-face {
  font-family: 'TikTokText-Bold';
  src: url('./assets/fonts/TikTokText-Bold.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'TikTokText-Medium';
  src: url('./assets/fonts/TikTokText-Medium.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'TikTokText-Regular';
  src: url('./assets/fonts/TikTokText-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

font-bold{
  font-family: 'TikTokText-Bold';
}

body {
  font-family: 'TikTokText-Bold', sans-serif !important;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
