*{
    margin: 0;
    padding: 0;    
}

html, body{
    height: 100%; /* Full height for the viewport */
    margin: 0; /* Remove default margin */
}

#root{
    height: 100%;
}

.page-wrapper {
    display: flex;
    flex-direction: column; /* Stack children vertically */
    min-height: 100vh; /* Full viewport height */
}

main {
    flex: 1; /* Take up remaining space */
    margin-top: calc(var(--header-height, 120px));
}



footer {
    /* Optional styles for footer */
    background-color: #f1f1f1;
    padding: 20px;
    text-align: center;
}

.transform-admin{
    padding-top: 110px;
    transition: transform 0.3s ease-in-out;
}

.nav-login-left{
    position: fixed;
    top: 5%; /* Center vertically */
    left: 0; /* Align to the left */
    transform: translateY(-50%); /* Adjust vertical alignment */
    display: flex;
    flex-direction: column;
    align-items: center;
    align-items: flex-start; /* Align items to the left */
    background-color: #fff; /* Optional background color */
    padding: 10px;
}

.nav-login-left .text-nav-left{
    font-size: 15px;
    cursor: pointer;
    margin-bottom: 2px;
}

.nav-login-left .text-nav-left:hover{
    color: #fec68b;
}

.nav-login-left .divider {
    width: 100%;
    height: 1px; /* Adjust the height as needed */
    background-color: black; /* Line color */
}

.nav-login-right {
    position: fixed;
    top: 5%; /* Align to the top */
    transform: translateY(-50%); 
    right: 0; /* Align to the right */
    padding: 10px;
    background-color: #fff; /* Optional background color */
}

.nav-login-right ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
}

.nav-login-right li {
    text-align: right; /* Align text to the right */
    margin: 10px; /* Optional space between items */
}

.nav-login-right .text-nav-right{
    font-size: 15px;
    cursor: pointer;
}

.nav-login-right .text-nav-right:hover{
    color: #fec68b;
    cursor: pointer;
}