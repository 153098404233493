.dialog-search-item{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1200;

    .dialog {
        background-color: white;
        border-radius: 8px;
        width: 300px;
        text-align: center ;
        width: 500px;

        h2{
            padding: 10px;
            background-color: bisque;
            border-radius: 8px 8px 0px 0px;
        }

    }

    .form-label{
        margin-bottom: 0px;
        display: flex;
        justify-content: flex-start;
        margin-left: 10px;
    }

    .button-dialog {
        margin-bottom: 10px;

        .btn{
            width: 100px;
            margin: 0px 10px 0px 10px;
        }
    }

    .button {
        margin: 0px 10px;
        padding: 10px 20px;
        background-color : #e7a250;
        color: white;
        border: none;
        border-radius: 5px;
        cursor: pointer;
    }

    .input-container {
        position: relative; /* Positions child elements relative to this container */
        max-width: 100%;
      }
      
      .option-item {
        position: absolute;         /* Makes it appear below the input */
        top: 100%;                  /* Positions it directly below the input */
        left: 0;
        width: 100%;                /* Matches the input width */
        background-color: #fff;     /* Optional: background color */
        border: 1px solid #ddd;     /* Optional: border */
        border-radius: 4px;
        max-height: 150px;          /* Optional: set max height for scrollable list */
        overflow-y: auto;           /* Enables scrolling for overflow */
        z-index: 10;                /* Ensures it appears above other elements */
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Optional: shadow for dropdown */
        padding-left: 0px;

        ul{
            list-style-type: none; 
            justify-content: start;
            padding: 0px;

            li{
                text-align: left; 
            }

            li:hover{
                background-color: bisque;
            }
        }

        
      }

      
}