.dialog-yes-no-admin{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1200;

    .dialog {
        background-color: white;
        border-radius: 8px;
        width: 300px;
        text-align: center ;
        width: 500px;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        h2{
            width: 100%;
            padding: 10px;
            background-color: bisque;
            border-radius: 8px 8px 0px 0px;
        }

        .text{
            padding-top: 10px;
            padding-bottom: 5px;
        }

        .button-dialog {
            margin-bottom: 10px;

            .btn{
                width: 100px;
                margin: 0px 10px 0px 10px;
            }
        }

    }
}