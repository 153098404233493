.dialog-OK{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1200;

    .dialog {
        background-color: white;
        border-radius: 8px;
        width: 300px;
        text-align: center ;
        width: 500px;

        h2{
            padding: 10px;
            background-color: bisque;
            border-radius: 8px 8px 0px 0px;
        }

        .text{
            padding-top: 10px;
            padding-bottom: 20px;
        }
    }

    .actions {
        padding: 10px;
    }

    .button {
        margin: 0px 10px;
        padding: 10px 20px;
        background-color : #e7a250;
        color: white;
        border: none;
        border-radius: 5px;
        cursor: pointer;
    }
}